.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}

.alpha-spinner .p-progress-spinner-circle { 
    animation: alpha 8s ease-in-out infinite; 
}

.p-button-xs {
    padding: 0.37rem 0.9rem;
    font-size: small;
}
  
@keyframes alpha { 
    100%, 
    0% { 
        stroke: var(--primary-color); 
    } 
    40% { 
        stroke: var(--primary-color); 
    } 
    66% { 
        stroke: var(--primary-color); 
    } 
    80%, 
    90% { 
        stroke: var(--primary-color); 
    } 
}

.simpleFileUpload .p-fileupload-buttonbar,
.simpleFileUpload .p-fileupload-content p-progressbar {
    display: none;
}

.simpleFileUpload .p-fileupload-content {
    border-radius: 6px;
    padding: 1rem 1rem;
}

p-dropdown.testclass .p-dropdown {
    position: relative;
}

p-dropdown.risk-color-low .p-dropdown::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #9BE89B;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
p-dropdown.risk-color-moderate .p-dropdown::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #FFEA82;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
p-dropdown.risk-color-medium .p-dropdown::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #FFCC7E;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
p-dropdown.risk-color-high .p-dropdown::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #FFAE7A;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
p-dropdown.risk-color-critical .p-dropdown::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #FF7171;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}